import { WebStorage } from "@shared/storage";
import { pushToDataLayer } from "../data/data-layer.facade";
import { handleAnalyticsError, attachClickEventListener, isMobilabonnementPath } from "@shared/Utils/analytics-utils";
import EcommerceAnalyticsRepository from "../ecommerce/voice/voice-analytics-repository";

type GA4SubscriptionToggleCTA = "for_alle" | "under_29" | "under_13";
type GA4SubscriptionCardCategory = "mer_info" | "legg_til" | "header_name" | "les_mer";

export type GA4SubscriptionCardEvent = {
  event: "mobilabonnement";
  element_category: GA4SubscriptionCardCategory;
  element_cta: string;
};

export type GA4SubscriptionToggleEvent = {
  event: "mobilabonnement";
  element_category: "subscription_toggle";
  element_cta: GA4SubscriptionToggleCTA;
};

const SUBSCRIPTION_TOGGLE_ELEMENTS: { id: string; cta: GA4SubscriptionToggleCTA }[] = [
  { id: "first-toggle", cta: "for_alle" },
  { id: "second-toggle", cta: "under_29" },
  { id: "third-toggle", cta: "under_13" },
];

const ecommerceRepository = new EcommerceAnalyticsRepository(new WebStorage(localStorage));

const fetchSubscriptionCardCTA = async (element: HTMLElement): Promise<string | undefined> => {
  const productIdElement = element.closest("[data-product-tracking-id]");
  const productTrackingId = productIdElement?.getAttribute("data-product-tracking-id");

  if (!productTrackingId) {
    handleAnalyticsError("Product tracking ID attribute not found", "fetchSubscriptionCardCTA");
    return;
  }

  const [product] = await ecommerceRepository.getProducts([productTrackingId]);

  if (!product) {
    handleAnalyticsError("Product not found in repository", "fetchSubscriptionCardCTA");
    return;
  }

  return product.name;
};

const initSubscriptionCardAnalytics = () => {
  const elements = document.querySelectorAll("[data-enable-cta-click-tracking]");
  if (!elements) return;

  attachClickEventListener(elements, async (e) => {
    const target = e.target as HTMLElement;
    const cta = await fetchSubscriptionCardCTA(target);
    const categoryElement = target.closest<HTMLElement>("[data-cta-tracking-category]");

    if (!cta || !categoryElement) {
      handleAnalyticsError(
        "Subscription card CTA or category not found",
        "initializeSubscriptionCardAnalytics",
        "error",
      );
      return;
    }

    const category = categoryElement.getAttribute("data-cta-tracking-category") as GA4SubscriptionCardCategory;
    sendSubscriptionCardEvent(category, cta);
  });
};

const initSubscriptionToggleAnalytics = () => {
  if (!isMobilabonnementPath()) return;

  SUBSCRIPTION_TOGGLE_ELEMENTS.forEach(({ id, cta }) => {
    const element = document.querySelector(`#${id}`);

    if (!element) return;

    attachClickEventListener(element, () => sendSubscriptionToggleEvent(cta));
  });
};

const sendSubscriptionCardEvent = (category: GA4SubscriptionCardCategory, cta: string) => {
  const event: GA4SubscriptionCardEvent = {
    event: "mobilabonnement",
    element_category: category,
    element_cta: cta,
  };

  pushToDataLayer(event);
};

const sendSubscriptionToggleEvent = (cta: GA4SubscriptionToggleCTA) => {
  const event: GA4SubscriptionToggleEvent = {
    event: "mobilabonnement",
    element_category: "subscription_toggle",
    element_cta: cta,
  };

  pushToDataLayer(event);
};

export const bootstrapSubscriptionAnalytics = () => {
  initSubscriptionCardAnalytics();
  initSubscriptionToggleAnalytics();
};
