﻿import { isEmptyString, trimChars } from "@shared/Utils";

const addParam = (params: string, value: string): string => {
  if (!params.includes(value)) {
    const list = params.split(",");
    list.push(value);
    params = trimChars(list.join(","), ", ");
  }
  return params;
};

const removeParam = (params: string, value: string): string => {
  if (params.includes(value)) {
    const list = params.split(",");
    const str = list.filter((e) => e !== value).join(",");
    params = trimChars(str, ", ");
  }
  return params;
};

const updateHrefWhenCheckChange = (input: HTMLInputElement, urlEl: HTMLElement): void => {
  const baseUrl = urlEl.getAttribute("href");
  const parsedUrl = new URL(baseUrl);
  const serviceType = input.dataset.serviceType;

  let services = parsedUrl.searchParams.get("services") ?? "";

  if (serviceType === "") {
    services = "";
  } else {
    services = input.checked ? addParam(services, serviceType) : removeParam(services, serviceType);
  }

  if (isEmptyString(services)) {
    parsedUrl.searchParams.delete("services");
  } else {
    parsedUrl.searchParams.set("services", services);
  }

  urlEl.setAttribute("href", parsedUrl.toString());
};

const toggleButtonFlash = (linkEl: HTMLElement): void => {
  linkEl.classList.remove("btn--flash");
  setTimeout(() => {
    linkEl.classList.add("btn--flash");
  }, 10);
};

const updateLinkText = (input: HTMLInputElement, linkEl: HTMLElement): void => {
  const text = input.dataset.buttonText;
  linkEl.innerHTML = text.replace("{0}", input.dataset.priceText);
};

const ServicesUrlAppender = (container: HTMLElement): void => {
  const linkEl = container.parentElement.querySelector<HTMLElement>(".js-services-link");
  const inputList = [].slice.call(container.querySelectorAll<HTMLInputElement>(".js-service-input")) as [
    HTMLInputElement,
  ];

  inputList.forEach((input: HTMLInputElement): void => {
    input.addEventListener("click", () => {
      updateHrefWhenCheckChange(input, linkEl);
      updateLinkText(input, linkEl);
      toggleButtonFlash(linkEl);
    });
  });
};

export const bootstrapServicesAppenderContainers = (): void => {
  const urlMergeContainer = [].slice.call(document.querySelectorAll(".js-service-switch")) as [HTMLElement];
  urlMergeContainer.forEach((containerItem: HTMLElement): void => {
    ServicesUrlAppender(containerItem);
  });
};
