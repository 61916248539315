import { sendGA4SearchClickEvent } from "@shared/Analytics/content/search-analytics";

const FORM_SELECTOR = ".js-search-form";
const INPUT_SELECTOR = "#search, #ord";
const AUTOCOMPLETE_ITEM_SELECTOR = ".ice-autocomplete-item";

const addAutoCompleteClickEvents = (searchInputs: NodeListOf<Element>) => {
  for (const input of searchInputs) {
    input.addEventListener("Iceac_queryValueChanged", () => {
      handleQueryValueChanged(input);
    });
  }
};

const addSearchSubmitEvents = (forms: NodeListOf<HTMLFormElement>) => {
  for (const form of forms) {
    form.addEventListener("submit", () => {
      handleSearchSubmit(form);
    });
  }
};

const handleSearchSubmit = (form: HTMLFormElement) => {
  const term = getSearchTerm(form);
  if (!term) return;
  const predictions = getSearchPredictions(form);
  sendGA4SearchClickEvent(term, predictions);
};

const handleQueryValueChanged = (input: Element) => {
  const form = input.closest<HTMLFormElement>(FORM_SELECTOR);
  if (!form) throw new Error("Could not find search form when handling queryValueChanged");
  handleSearchSubmit(form);
  form.submit();
};

const getSearchTerm = (form: HTMLFormElement) => {
  const searchInput = form.querySelector<HTMLInputElement>(INPUT_SELECTOR);
  if (!searchInput) return null;
  return searchInput.value;
};

const getSearchPredictions = (form: HTMLFormElement) => {
  const predictionElements = form.querySelectorAll<HTMLElement>(AUTOCOMPLETE_ITEM_SELECTOR);
  if (!predictionElements) return [];

  const predictions = Array.from(predictionElements)
    .map((prediction: HTMLElement) => prediction.innerText)
    .filter(Boolean);
  return predictions;
};

export const bootstrapSearchEvents = (doc: Document | ShadowRoot = document) => {
  const searchInputs = doc.querySelectorAll(INPUT_SELECTOR);
  addAutoCompleteClickEvents(searchInputs);

  const searchForm = doc.querySelectorAll<HTMLFormElement>(FORM_SELECTOR);
  addSearchSubmitEvents(searchForm);
};
